const toastDefaultOptions = {
  theme: 'colored',
  pauseOnHover: true,
  position: "top-center",
  bodyStyle: {
    alignItems: "center"
  },
  style: {
    top: "1vh",
    minWidth: "25vw",
    minHeight: "5vh",
  }
};

export default toastDefaultOptions;