import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import React, { useEffect, useState } from "react";
import { Row, Form, Modal, Button, InputGroup } from "react-bootstrap";
import DuplicateIcon from "assets/img/icons/request-system/duplicate.svg";


export default function DuplicateButton({data, type, handleDuplicate}) {
  const { auth } = useAuth();
  const [valueLoja, setValueLoja] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [editValues, setEditValues] = useState(data);
  const handleModalClose = () => setModalShow(false);
  const [comboLojaValues, setComboLojaValues] = useState([]);

  const adjustLojaDataToCombo = (data) => {
    return data.map((loja) => {
      return adjustUniqueLojaDataToShow(loja);
    });
  };
  
  const adjustUniqueLojaDataToShow = (loja) => {
    return {
      value: loja.id,
      nome: loja.nome,
      label: loja.nome,
    }
  };

  const handleChange = (event, type) => {
    var newData = {...editValues};

    switch(type) {
      case 'number': {
        const value =  parseFloat(event.target.value);
        newData[event.target.name] = value;

        break;
      }
      default:
        newData[event.target.name] = event.target.value;
    }

    setEditValues(newData);
  };

  useEffect(async () => {
    const lojas = await baseController.get('Loja/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });

    setComboLojaValues(() => {
      const value = adjustLojaDataToCombo(lojas.data);

      return value;
    });
  }, []);

  useEffect(async () => {
    if(valueLoja && valueLoja.value) {
      setEditValues(() => {
        var newData = {...editValues}

        newData.loja = null;
        newData.lojaId = valueLoja.value;

        return newData;
      });
    }
  }, [valueLoja])
  
  return (
    <>
      <Button 
        title='Duplicar'
        className={'dashboard-button-index'}
        onClick={() => setModalShow(true)}
      >
        <img
          alt='Duplicar'
          src={DuplicateIcon}
          className='button-icon'
        />
      </Button>

      <Modal
        centered
        size="lg"
        show={modalShow}
        onHide={handleModalClose}
        aria-labelledby="duplicate-modal-title"
      >
      <Modal.Header closeButton>
        <Modal.Title
          id="duplicate-modal-title"
          className="delete-modal-title"
        >
          Duplicar { type }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          type === 'Loja' &&
          <Form>
            <Form.Group>
              <Form.Label>Nome da Loja</Form.Label>
              <Form.Control
                required
                type="text"
                name="nome"
                value={editValues.nome}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </Form> 
        }
        {
          type === 'Produto' &&
          <Form>
            <Row>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupName"
              >
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  required
                  name="nome"
                  type="text"
                  minLength={2}
                  maxLength={100}
                  value={editValues.nome}
                  placeholder="Nome do produto"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupDescricao"
              >
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  rows={4}
                  type="text"
                  as="textarea"
                  minLength={0}
                  maxLength={400}
                  name="descricao"
                  value={editValues.descricao}
                  onChange={(e) => handleChange(e)}
                  placeholder="Descrição do produto"
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupPreco"
              >
                <Form.Label>Preço</Form.Label>
                <InputGroup>
                  <InputGroup.Text>R$</InputGroup.Text>
                  <Form.Control
                    required
                    name="preco"
                    type="number"
                    value={editValues.preco ?? 0}
                    placeholder="0,00"
                    onChange={(e) => handleChange(e, 'number')}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group 
                controlId="formLojaId"
                className="mb-3 form-group-select-with-button"
              >
                <Form.Label>Loja</Form.Label>
                <Select
                  name="lojaId"
                  isMulti={false}
                  value={valueLoja}
                  closeMenuOnSelect
                  options={comboLojaValues}
                  classNamePrefix="react-select"
                  placeholder="Selecione a loja"
                  onChange={value => setValueLoja(value)}
                  className={data.lojaId === null ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}
                />
              </Form.Group>
            </Row>
          </Form>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button 
          title="Cancelar"
          variant="secundary"
          onClick={handleModalClose}
          className="delete-modal-cancel-button"
        >
          Cancelar
        </Button>
        <Button 
          title="Duplicar"
          variant="primary"
          onClick={() => handleDuplicate(editValues)}
        >
          Duplicar
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  )
}