import useAuth from 'hooks/useAuth';
import { Row, Col, Card } from 'react-bootstrap';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import ReloadButton from '../sistema/reloadButton';
import InsertButton from '../sistema/insertButton';
import React, { useEffect, useState } from 'react';
import ActionsColumn from '../sistema/actionsColumn';
import { produtoRoles } from '../sistema/allowedRoles';
import FullScreenLoading from '../sistema/fullScreenLoading';
import AdvanceTable from '../../common/advance-table/AdvanceTable';
import CalculateRowQuantity from '../sistema/calculateRowQuantity';
import { emptyMessage, errorMessage } from '../sistema/gridMessages';
import AdvanceTableWrapper from '../../common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from '../../common/advance-table/AdvanceTableSearchBox';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';

export default function Produto() {
  let timeout;
  window.onresize = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      CalculateRowQuantity(itemsPerPage, setItemsPerPage)
    }, 500);
  };

  const { auth } = useAuth();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isSlow, setIsSlow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [authorized, setAuthorized] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [message, setMessage] = useState(emptyMessage);

  const goToPage = (page) => {
    setPage(page)
  };

  const columns = [
    {
      accessor: 'imagem',
      Header: '',
      Cell: (rowData) => {
        const { imagem } = rowData.row.original;
        
        return (
          <img 
            src={imagem}
            loading='lazy'
            style={{ width: '40px', maxHeight: '50px' }} 
          />
        )
      },
      cellProps:{
        style: {
          width: '50px'
        }
      }
    },
    {
      accessor: 'nome',
      Header: 'Nome'
    },
    {
      accessor: 'loja.nome',
      Header: 'Loja'
    },
    {
      accessor: 'id',
      Header: 'Ações',
      Actions: true,
      Cell: (rowData) => {
        const Data = rowData.row.original;
        const { id } = rowData.row.original;

        return (
          <ActionsColumn
            showEdit
            showDelete
            data={Data}
            showDuplicate
            type={'Produto'}
            getData={getData}
            setLoaded={setLoaded}
            controller={baseController}
            deleteRoute={`Produto/${id}`}
            editRoute={`/produto/edit/${id}`}
            handleDuplicate={handleDuplicate}
          />
        );
      },
      cellProps: {
        style: {
          width: '75px'
        }
      }
    }
  ];

  const handleDuplicate = async(produtoData) => {
    setLoaded(false);

    await baseController.post("Produto/Duplicate", produtoData, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });

    await getData();

    setLoaded(true);
  };

  const getData = async () => {
    setData([]);
    setLoaded(false);

    let messageTimeout = setTimeout(() => {
      if(!loaded)
        setIsSlow(true);
    }, 5000);

    if(itemsPerPage > 0)
      await baseController.get('Produto', {
        params: {
          page,
          itemsPerPage,
          filterValue: globalFilter
        },
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      }).then((res) => {
        setData(res.data.produtos);
        setTotalPages(res.data.totalPages);

        if(res.data.produtos.length === 0)
          setMessage(emptyMessage);

        if(res.data.totalPages < page) {
          goToPage(1);
          setCurrentPage(1);
        }
      }).catch(() => setMessage(errorMessage))
      .finally(() => {
        setLoaded(true);
        setIsSlow(false);
        clearTimeout(messageTimeout);
      });
  };

  useEffect(() => {
    getData();
  }, [page, globalFilter, itemsPerPage]);

  useEffect(() => {
    const isAuthorized = produtoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized)
      CalculateRowQuantity(itemsPerPage, setItemsPerPage);
  }, []);

  if(!loaded) return (<FullScreenLoading isSlow={isSlow} />);

  if(!authorized) return ( <Error401 />);

  return (
    <Card className='grid-card'>
      <Card.Header>
        <h1 className='index-table-title'>
          Produtos
        </h1>
      </Card.Header>
      <Card.Body className='table-card-body'>
        <AdvanceTableWrapper
          sortable
          pagination
          columns={columns}
          data={data ? data : []}
          perPage={itemsPerPage ? itemsPerPage : 10}
        >
          <Row className="flex-end-center mb-3">
            <Col className='grid-actions'>
              <InsertButton route='/produto/edit' />
              <ReloadButton getData={getData} />
            </Col>
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox 
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder = 'Digite sua pesquisa aqui'
              />
            </Col>
          </Row>
          <AdvanceTable
            table
            rowClassName="align-middle white-space-nowrap"
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            tableProps={{
              striped: true,
              bordered: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          { !data?.length ?
            <div className='mt-3 table-no-data'>
              <p>{message}</p>
            </div>
            : <></>
          }
          <div className='table-paginator-wrapper'>
            <div className="mt-3">
              <AdvanceTablePagination 
                setPage={setPage}
                goToPage={goToPage}
                pageCount={totalPages}
                pageIndex={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
}