import { jsPDF } from 'jspdf';
import useAuth from 'hooks/useAuth';
import html2canvas from 'html2canvas';
import VendaLoja from './graficos/vendaLoja';
import { useParams } from 'react-router-dom';
import BackButton from '../sistema/backButton';
import DateColumn from '../sistema/dateColumn';
import baseController from 'api/base.controller';
import ResumoEvento from './graficos/resumoEvento';
import React, { useState, useEffect } from 'react';
import ReloadButton from '../sistema/reloadButton';
import DetailButton from '../sistema/detailButton';
import ResumoProduto from './graficos/resumoProduto';
import Error401 from 'components/pages/errors/Error401';
import DownloadPDFButton from '../sistema/downloadPDFButton';
import FullScreenLoading from '../sistema/fullScreenLoading';
import VendaPorHoraEvento from './graficos/vendaPorHoraEvento';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import VendaFormaPagamento from './graficos/vendaFormaPagamento';
import FieldValidationMessage from '../sistema/fieldValidationMessage';
import { defaultDashboardRoles } from 'components/pages/sistema/allowedRoles';

const EventoDashboard = () => {
  const { id } = useParams();
  const { auth } = useAuth();
  const [eventoInfo, setEventoInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [vendaLojaData, setVendaLojaData] = useState([]);
  const [vendaHoraData, setVendaHoraData] = useState([]);
  const [resumoEventoData, setResumoEventoData] = useState([]);
  const [resumoProdutoData, setResumoProdutoData] = useState([]);
  const [formaPagamentoData, setFormaPagamentoData] = useState([]);

  const [todayActive, setTodayActive] = useState(false);
  // const [thisWeekActive, setThisWeekActive] = useState(false);
  const [last15Active, setLast15Active] = useState(false);
  const [last30Active, setLast30Active] = useState(true);
  const [yesterdayActive, setYesterdayActive] = useState(false);
  const [wholePeriodActive, setWholePeriodActive] = useState(false);
  const [customPeriodActive, setCustomPeriodActive] = useState(false);

  const [finalDate, setFinalDate] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [filterChanged, setFilterChanged] = useState(false);

  const handleDateFilter = (period) => {
    const definePeriod = (period, subtractFinal = 0) => {
      const final = new Date();
      const initial = new Date();

      initial.setDate(initial.getDate() - period);
      initial.setHours(0, 0, 0, 0);

      setInitialDate(initial);

      final.setDate(final.getDate() - subtractFinal);
      final.setHours(23, 59, 59, 999);

      setFinalDate(final);
    }

    switch(period) {
      case 'today': {
        definePeriod(0);
        break;
      }
      case 'yesterday': {
        definePeriod(1, 1)
        break;
      }
      case 'last15': {
        definePeriod(15);
        break;
      }
      case 'last30': {
        definePeriod(30);
        break;
      }
      default: {
        setFinalDate(null);
        setInitialDate(null);
      }
    }

    setFilterChanged(true);
  };

  const loadData = async () => {
    setIsLoading(true);

    const params = {
      dataFinal: finalDate,
      dataInicial: initialDate
    };

    doLoadData(params);
  };

  const handleLoadDataCustomFilter = () => {
    setIsLoading(true);

    const adjustedFinalDate = new Date(finalDate);

    adjustedFinalDate.setDate(adjustedFinalDate.getDate() + 1);
    adjustedFinalDate.setHours(23, 59, 59);

    const params = {
      dataFinal: adjustedFinalDate,
      dataInicial: initialDate
    }

    doLoadData(params);
  };

  const doLoadData = async (params) => {
    const headers = {
      Authorization: auth.token ?? JSON.parse(localStorage.getItem('auth')).token
    };

    const resResumoEvento = await baseController.get(`Dashboard/ResumoEvento/${id}`, { params, headers });
    const resVendaLoja = await baseController.get(`Dashboard/VendaLojaEvento/${id}`, { params, headers });
    const resVendaHoraData = await baseController.get(`Dashboard/VendaHoraEvento/${id}`, { params, headers });
    const resEventoInfos = await baseController.get(`Dashboard/GetSpecificEventoInfo/${id}`, { params, headers });
    const resResumoProduto = await baseController.get(`Dashboard/ResumoProdutoEvento/${id}`, { params, headers });
    const resFormaPagamento = await baseController.get(`Dashboard/FormaPagamentoEvento/${id}`, { params, headers });

    setEventoInfo(resEventoInfos.data);
    setVendaLojaData(resVendaLoja.data);
    setVendaHoraData(resVendaHoraData.data);
    setResumoEventoData(resResumoEvento.data);
    setResumoProdutoData(resResumoProduto.data);
    setFormaPagamentoData(resFormaPagamento.data);

    setIsLoading(false);
    setFilterChanged(false);
  };

  useEffect(() => {
    if(filterChanged) loadData();
  }, [filterChanged]);

  useEffect(async () => {
    const isAuthorized = defaultDashboardRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);
    
    if (isAuthorized) handleDateFilter('last30');
  }, []);

  if(isLoading) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  const generatePDF = async () => {
    setIsLoading(true);

    const element = document.getElementById('pdf-material');

    const canvas = await html2canvas(element, {scale: 2});

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: 'mm',
      format: [200, 287],
    });
  
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();const imgWidth = canvas.width;
    const imgHeight = canvas.height;

    const ratio = imgWidth / pdfWidth;
    const scaledHeight = imgHeight / ratio;
    const pages = Math.ceil(scaledHeight / pdfHeight);

    for (let i = 0; i < pages; i++) {
      const srcY = i * pdfHeight * ratio;
      const srcHeight = pdfHeight * ratio;
      const singlePageCanvas = document.createElement('canvas');
      singlePageCanvas.width = imgWidth;
      singlePageCanvas.height = srcHeight;

      const ctx = singlePageCanvas.getContext('2d');
      ctx.drawImage(canvas, 0, srcY, imgWidth, srcHeight, 0, 0, imgWidth, srcHeight);

      const singlePageData = singlePageCanvas.toDataURL('image/png');
      if (i > 0) {
          pdf.addPage();
      }
      pdf.addImage(singlePageData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    }
    
    pdf.save(`${eventoInfo.nome} ${eventoInfo.local}`);
    setIsLoading(false);
  };

  const dashboard = (showButtons) => (
    <Card className='dashboard-card'>
      { showButtons ? 
        <Card.Header>
          <h1 className='dashboard-title'>
          {eventoInfo.nome + ' - ' + eventoInfo.local}
          </h1>
          <div className='fw-600 flex-row align-center event-period-wrapper'>
            <DateColumn date={eventoInfo.dataInicio}/>
            <p className='date-spacer'>-</p>
            <DateColumn date={eventoInfo.dataTermino}/>
          </div>
          <Row className="flex-end-center mb-3">
            <Col>
            <div className='grid-actions'>
              <BackButton />
              <ReloadButton getData={loadData}/>
              <DetailButton
                gridData={eventoInfo}
                route={`/evento/detail/${id}`}
                dashboardClass='pedido-button-from-dashboard'
              />
              <DownloadPDFButton generatePDF={generatePDF} />
            </div>
            </Col>
          </Row>
          <Row className='grid-actions dashboard-filter-actions'>
            <Button 
              className='button-date-filter d-inline-block text-truncate' 
              variant={todayActive ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(true);
                // setThisWeekActive(false);
                setLast15Active(false);
                setLast30Active(false);
                setYesterdayActive(false);
                setWholePeriodActive(false);
                setCustomPeriodActive(false);

                handleDateFilter('today');
              }}
            >Hoje</Button>

            <Button 
              className='button-date-filter'
              variant={yesterdayActive ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(false);
                // setThisWeekActive(false);
                setLast15Active(false);
                setLast30Active(false);
                setYesterdayActive(true);
                setWholePeriodActive(false);
                setCustomPeriodActive(false);

                handleDateFilter('yesterday');
              }}
            >Ontem</Button>

            {/* <Button 
              className='button-date-filter'
              variant={thisWeekActive ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(false);
                setThisWeekActive(true);
                setYesterdayActive(false);
                setWholePeriodActive(false);

                handleDateFilter('thisWeek');
              }}
            >Semana</Button> */}

            <Button 
              className='button-date-filter'
              variant={last15Active ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(false);
                // setThisWeekActive(true);
                setLast15Active(true);
                setLast30Active(false);
                setYesterdayActive(false);
                setWholePeriodActive(false);
                setCustomPeriodActive(false);

                handleDateFilter('last15');
              }}
            >15 Dias</Button>

            <Button 
              className='button-date-filter'
              variant={last30Active ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(false);
                // setThisWeekActive(true);
                setLast15Active(false);
                setLast30Active(true);
                setYesterdayActive(false);
                setWholePeriodActive(false);
                setCustomPeriodActive(false);

                handleDateFilter('last30');
              }}
            >30 Dias</Button>

            <Button 
              className='button-date-filter long'
              variant={wholePeriodActive ? 'success' : 'primary'}
              onClick={() => {
                setTodayActive(false);
                setLast15Active(false);
                setLast30Active(false);
                // setThisWeekActive(false);
                setYesterdayActive(false);
                setWholePeriodActive(true);
                setCustomPeriodActive(false);

                handleDateFilter('wholePeriod');
              }}
            >Todo o Período</Button>
          </Row>
          <Row>
            <Form onSubmit={handleLoadDataCustomFilter}>
              <Row className='custom-date-filter-row'>
                <Col>
                  <Form.Group controlId="formGroupDataInicio">
                    <Form.Control
                      required
                      name="dataInicio"
                      type="date"
                      value={initialDate}
                      selected={initialDate}
                      placeholder="Data de Início do Evento"
                      onChange={(e) => setInitialDate(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage fieldName="Data de Início" />
                    </Form.Control.Feedback>
                  </ Form.Group>
                  <Form.Label>Data Inicial</Form.Label>
                </Col>
                <Col>
                  <Form.Group controlId="formGroupDataTermino">
                    <Form.Control
                      required
                      name="dataTermino"
                      type="date"
                      value={finalDate}
                      selected={finalDate}
                      onChange={(e) => setFinalDate(e.target.value)}
                      placeholder="Data de Encerramento do Evento"
                      isInvalid={new Date(finalDate).getTime() < new Date(initialDate).getTime()}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage 
                        greaterThan
                        fieldName="Data de Encerramento"
                        fieldGreaterThan="Data de Início"
                      />
                    </Form.Control.Feedback>
                    <Form.Label>Data Final</Form.Label>
                  </ Form.Group>
                </Col>
                <Col>
                  <Button type='submit'
                    variant={customPeriodActive ? 'success' : 'primary'}
                    onClick={() => { 
                      setTodayActive(false);
                      setLast15Active(false);
                      setLast30Active(false);
                      setYesterdayActive(false);
                      setWholePeriodActive(false);
                      setCustomPeriodActive(true);
                    }}>Filtrar</Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Card.Header> : <></>
      }
      <div id='pdf-material'>
        <Row className="g-1 mb-1">
          <Col className="dashboard-resumo" md={12} xxl={3}>
            <ResumoEvento data={resumoEventoData} />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6}>
            <VendaLoja data={vendaLojaData} />
          </Col>
          <Col lg={6}>
            <VendaFormaPagamento data={formaPagamentoData} radius={['70%', '100%']} />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={12}>
            <VendaPorHoraEvento data={vendaHoraData}/>
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={12}>
            <ResumoProduto data={resumoProdutoData} showDetails/>
          </Col>
        </Row>
      </div>
    </Card>
  );

  return dashboard(true);
};

export default React.memo(EventoDashboard);