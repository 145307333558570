import useAuth from 'hooks/useAuth';
import { Row, Card } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import DateColumn from '../sistema/dateColumn';
import BackButton from '../sistema/backButton';
import Error401 from 'components/pages/errors/Error401';
import ReloadButton from '../sistema/reloadButton';
import React, { useState, useEffect } from "react";
import { eventoRoles } from '../sistema/allowedRoles';
import FullScreenLoading from '../sistema/fullScreenLoading';
import CalculateRowQuantity from '../sistema/calculateRowQuantity';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import baseController from 'api/base.controller';
import { emptyMessage, errorMessage } from '../sistema/gridMessages';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from '../../common/advance-table/AdvanceTablePagination';
import PedidoInformacoesFiscaisModal from './pedidoInformacoesFiscaisModal';

function EventoDetail() {
  const { auth } = useAuth();
  const { state } = useLocation();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState();
  const [authorized, setAuthorized] = useState(false);
  const [globalFilter] = useState("");

  let timeout;

  window.onresize = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      CalculateRowQuantity(itemsPerPage, setItemsPerPage)
    }, 500);
  };

  useEffect(() => {
    const isAuthorized = eventoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if (isAuthorized)
      CalculateRowQuantity(itemsPerPage, setItemsPerPage);
  }, []);

  useEffect(() => {
    getData();
  }, [page, globalFilter, itemsPerPage]);

  const goToPage = (page) => {
    setPage(page)
  };

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency", 
    currency: "BRL"
  });

  const columns = [
    {
      accessor: 'lojaNome',
      Header: 'Loja'
    },
    {
      accessor: 'caixaNome',
      Header: 'Caixa'
    },
    {
      accessor: 'formaPagamentoNome',
      Header: 'Forma de Pagamento'
    },
    {
      accessor: 'valor',
      Header: 'Valor',
      Cell: (rowData) => {
        const { valor } = rowData.row.original;

        return (
          <>
            { formatter.format(valor) }
          </>
        )
      }
    },
    {
      accessor: 'detalhe',
      Header: 'Detalhes'
    },
    {
      accessor: 'data',
      Header: 'Data',
      Cell: (rowData) => {
        const { data } = rowData.row.original;

        return (
          <DateColumn 
            showTime
            date={data} 
          />
        )
      }
    },
    {
      accessor: 'tipo',
      Header: 'Tipo de Pedido',
      Cell: (rowData) => {
        const { tipo } = rowData.row.original;
        
        switch(tipo) {
          case 'X':
            return (<>Cancelamento</>);
          case 'C':
            return (<>Compra</>);
          case 'E':
            return (<>Estorno</>);
        }
      }
    },
    {
      accessor: 'id',
      Header: 'Ações',
      Actions: true,
      Cell: (rowData) => {
        const { pedidoInformacoesFiscaisId, chaveNFe, serie, caminhoDanfe, data, eventoId, id } = rowData.row.original;

        return (
          <>
           {
            pedidoInformacoesFiscaisId !== null &&
            <PedidoInformacoesFiscaisModal
              id={id}
              data={data}
              eventoId={eventoId}
              fiscalData={{chaveNFe, serie, caminhoDanfe}}
            />
           }
          </>
        );
      },
      cellProps:{
        style: {
          width: '125px'
        }
      }
    }
  ];

  const getData = async () => {
    setData([]);
    setLoading(true);

    if (itemsPerPage > 0) {
      baseController.post(`Pedido/${state?.id}`, 
        { LojasId: [], Page: page, ItemsPerPage: itemsPerPage },
        { headers: { 'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token } }
      ).then(res => {
        setData(res.data.pedidos);
        setTotalPages(res.data.totalPages);

        if (res.data.pedidos.length === 0)
          setMessage(emptyMessage);

        if (res.data.totalPages < page) {
          goToPage(1);
          setCurrentPage(1)
        }
      }).catch(() => setMessage(errorMessage))
      .finally(() => setLoading(false));
    }
  }
  
  if(!authorized) return (<Error401 />);

  if(loading) return (<FullScreenLoading isSlow={false} />);

  return (
    <Card
      id="pedidos-detail"
      className="edit-card"
    >
      <Card.Header
        className="bg-light px-4 py-3"
      >
        <h1 className='dashboard-title'>
          { `${state?.nome} - ${state?.local}` }
        </h1>
        <div className='fw-600 flex-row align-center event-period-wrapper'>
          <DateColumn date={state?.dataInicio}/>
          <p className='date-spacer'>-</p>
          <DateColumn date={state?.dataTermino}/>
        </div>
        <Row className='grid-actions'>
          <BackButton />
          <ReloadButton getData={getData}/>
        </Row>
        <Row className='grid-actions dashboard-filter-actions'>

        </Row>
      </Card.Header>
      <Card.Body className='table-card-body'>
        <AdvanceTableWrapper
          sortable
          pagination
          columns={columns}
          data={data ?? []}
          perPage={itemsPerPage ?? 10}
        >
          <AdvanceTable
            table
            rowClassName="align-middle white-space-nowrap"
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          { !data.length ?
            <div className='mt-3 table-no-data'>
              <p>{message}</p>
            </div>
            : <></>
          }
          <div className='table-paginator-wrapper'>
            <div className="mt-3">
              <AdvanceTablePagination 
                setPage={setPage}
                goToPage={goToPage}
                pageCount={totalPages}
                pageIndex={currentPage}
                setCurrentPage={setCurrentPage} 
              />
            </div>
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
}

export default React.memo(EventoDetail);