import axios from 'axios';
import { toast } from 'react-toastify';
import toastDefaultOptions from '../components/pages/sistema/toastDefaultOptions';

export const baseController = 
  axios.create({
    timeout: 200000,
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'x-functions-key': process.env.REACT_APP_API_KEY
    }
  });

baseController.interceptors
              .response
              .use(
                (response) => {
                  switch(response.config.method) {
                    case "post":
                      if(response?.request?.responseURL?.includes("Logout"))
                        toast.success(`${response.data}.`, {...toastDefaultOptions, autoClose: 1000});
                      else if(response?.request?.responseURL?.includes("Login"))
                        toast.success('Login realizado com sucesso.', {...toastDefaultOptions, autoClose: 1000});
                      else
                      toast.success("Registro adicionado com sucesso.", {...toastDefaultOptions, autoClose: 1000});
                      break;
                    case "delete":
                      toast.success("Registro removido com sucesso.", {...toastDefaultOptions, autoClose: 1000});
                      break;
                    case "put":
                      toast.success("Registro atualizado com sucesso.", {...toastDefaultOptions, autoClose: 1000});
                      break;
                  }

                  return response;
                },
                (error) => {
                  toast.error(error?.response?.data ?? error.message ?? "Erro ao realizar requisição, tente novamente.", toastDefaultOptions);
                }
              );

export default baseController;